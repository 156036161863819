import React, { createContext, useContext, useState } from 'react'

const LanguageContext = createContext({})

const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState('pt-br')

  const toggleLanguage = (value) => {
    if (value === 'en-us') {
      setLang('en-us')
    } else {
      setLang('pt-br')
    }
  }

  return (
    <LanguageContext.Provider value={{ toggleLanguage, lang }}>
      {children}
    </LanguageContext.Provider>
  )
}

const useLanguage = () => useContext(LanguageContext)

export {
  LanguageProvider,
  useLanguage
}
