import React, { useEffect, useState, Suspense, lazy } from 'react'

import './App.scss';
import { useTheme } from './hooks/themes/themes'
import Header from './components/header'
import Home from './components/home'
import Backdrop from './components/UI/backdrop/backdrop'
const About = lazy(() => import('./components/about'))
const Services = lazy(() => import('./components/services'))
const Experience = lazy(() => import('./components/experience'))
const Works = lazy(() => import('./components/works'))
const Posts = lazy(() => import('./components/posts'))
const Contact = lazy(() => import('./components/contact'))


// Depois tenho que user attributeSet() para setar a lang dinamicamente na tag <html />
function App() {
  const { toggleTheme } = useTheme()
  const [theme, setTheme] = useState(() => {
    const atualTheme = localStorage.getItem('@profile:theme')
    if (atualTheme === 'dark') {
      return 'dark'
    } else {
      return 'light'
    }
  })
  const [headerMobile, setHeaderMobile] = useState(false)

  useEffect(() => {
    toggleTheme(theme)
  })

  const handle = (item) => {
    setTheme(item)
    toggleTheme(item)
  }

  const handleHeaderMobile = () => {
    setHeaderMobile(!headerMobile)
  }

  return (
    <div className='app'>
      <Backdrop show={headerMobile} clicked={handleHeaderMobile} />
      <button 
        className='button-menu-mobile' 
        onClick={handleHeaderMobile} style={headerMobile ? {transform: `translateX(250px)`} : {}} 
        aria-label="Menu"
      />
      <Header themeClicked={handle} handleHeader={headerMobile} />
      <div className='homepage'>
        <Home id='home' />
        <Suspense fallback={<div>Loading...</div>}>
          <About id='about' />
          <Services id='services' />
          <Experience id='experience' />
          <Works id='work' />
          <Posts id='posts' />
          <Contact id='contact' />
        </Suspense>
      </div>
    </div>
  );
}

export default App;
