import React, { createContext, useContext } from 'react'

const ThemeContext = createContext({});

const ThemeProvider = ({ children }) => {
  const user = {
    theme: "light"
  };

  const toggleTheme = (e = 'light') => {
    if (e === 'dark') {
      user.theme = 'dark'
      localStorage.setItem('@profile:theme', 'dark')
    } else {
      user.theme = 'light'
      localStorage.setItem('@profile:theme', 'light')
    }
    

    document.documentElement.className = "";
    document.documentElement.classList.add(`theme-${user.theme}`);
  }

  return (
    <ThemeContext.Provider value={{ toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

function useTheme() {
  const context = useContext(ThemeContext);

  return context
}

export { ThemeProvider, useTheme };