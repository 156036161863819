import React, { createContext, useContext, useState } from 'react'

export const fadeFunction = (options, nameClass) => {
  const appearOptions = {
    threshold: options,
    rootMargin: "0px 0px -100px 0px"
  }

  return new IntersectionObserver((entries, appearOnScroll) => {
    entries.forEach(entry => {
      // console.log(entry)
      if (!entry.isIntersecting) {
        return;
      } else {
        // console.log(nameClass)
        entry.target.classList.add(nameClass)
      }
    })
  }, appearOptions)
}

const ActivedLinkContext = createContext({})

const ActivedLinkProvider = ({ children }) => {
  const [selectLinkScroll, setSelectLinkScroll] = useState('home')

  const nameLink = (options, name, nameClass) => {
      const appearOptions = {
        threshold: options,
        // rootMargin: "0px 0px -100px 0px"
      }
    
      return new IntersectionObserver((entries, appearOnScroll) => {
        entries.forEach(entry => {
          // console.log(entry)
          if (!entry.isIntersecting) {
            return;
          } else {
            // console.log('hook: ', name)
            setSelectLinkScroll(name)
          }
        })
      }, appearOptions)
  }
  return (
    <ActivedLinkContext.Provider value={{ nameLink, selectLinkScroll }}>
      {children}
    </ActivedLinkContext.Provider>
  )
}

const useLink = () => useContext(ActivedLinkContext)

export {
  ActivedLinkProvider,
  useLink
}