import { BiChevronsDown } from 'react-icons/bi'

import Profile from '../../assets/profile.webp'
import './home.scss'

const Home = ({ id }) => {

  return (
    <div id={id} className='home'>
      <div className='home__image' style={{backgroundImage: `url(${Profile})`}} />
      <h3 className='home__name'>Tiago Emerick</h3>
      <p className='home__skills'>Sou UI/UX designer Web e Mobile</p>
      <a href='#contact' className='home__contact'>CONTATE-ME</a>
      <div className='home__block'>
        <span>Scroll Down</span>
        <a href="#about" aria-label='Scroll down'><BiChevronsDown /></a>
      </div>
    </div>
  )
}

export default Home