export const en = {
  home: 'Home',
  about: 'About',
  service: 'Services',
  experience: 'Experience',
  work: 'Works',
  post: 'Posts',
  contact: 'Contact',
  themeLight: 'Light',
  themeDark: 'Dark'
}

export const pt = {
  home: 'Inicio',
  about: 'Sobre',
  service: 'Serviços',
  experience: 'Experiencia',
  work: 'Trabalhos',
  post: 'Postagens',
  contact: 'Contato',
  themeLight: 'Claro',
  themeDark: 'Escuro'
}