import { useEffect, useState } from 'react'
import {
  IoHomeOutline,
  IoPersonOutline,
  FiBriefcase,
  IoSchoolOutline,
  FiLayers,
  RiFilePaper2Line,
  IoChatbubblesOutline,
  BsMoon,
  BsSun,
  IoLanguageSharp,
  ImArrowRight
} from "react-icons/all";

import './header.scss'
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { pt, en } from './header-lang'
import { useLanguage } from '../../hooks/language/language'

const Header = ({ themeClicked, handleHeader }) => {
  const [open, setOpen] = useState(false)
  const [nameLink, setNameLink] = useState('home')
  const [rotate, setRotate] = useState(() => {
    if (localStorage.getItem('@profile:theme') === 'light') {
      return false
    } else {
      return true
    }
  })

  const { lang, toggleLanguage } = useLanguage()

  useEffect(() => {
    setOpen(handleHeader)
  }, [handleHeader])

  const handleTheme = (item) => {
    themeClicked(item)
    setRotate(!rotate)
  }

  const handleLang = (value) => {
    // console.log(value.target.value)
    toggleLanguage(value.target.value)
  }

  const handleHeaderOpen = () => {
    setOpen(() => {
      return !open
    })
  }

  const handleLinkActive = (nameLink) => {
    setNameLink(nameLink)
  }

  return (
    <div 
      className={`header ${open && 'open'} ${handleHeader && 'back-mobile'}`} 
      style={handleHeader ? {transform: `translateX(0px)`, position: 'fixed', zIndex: '1000'} : {}}
    >
      <Logo className='logo' />
      <ul className='header__list'>
        <li className='header__item'>
          <a 
            href='#home' 
            className={`header__link ${nameLink === 'home' && 'link-active'}`}
            onClick={() => handleLinkActive('home')}>
            <IoHomeOutline />
            <span className={`header__link--text ${ open && 'text-open'}`}>
              {lang === 'pt-br' ? pt.home : en.home}
            </span>
          </a>
        </li>
        <li className='header__item'>
          <a 
            href='#about' 
            className={`header__link ${nameLink === 'about' && 'link-active'}`}
            onClick={() => handleLinkActive('about')}>
            <IoPersonOutline />
            <span className={`header__link--text ${ open && 'text-open'}`}>
            {lang === 'pt-br' ? pt.about : en.about}
            </span>
          </a>
        </li>
        <li className='header__item'>
          <a href='#services' 
            className={`header__link ${nameLink === 'service' && 'link-active'}`}
            onClick={() => handleLinkActive('service')}>
            <FiBriefcase />
            <span className={`header__link--text ${ open && 'text-open'}`}>
            {lang === 'pt-br' ? pt.service : en.service}
            </span>
          </a>
        </li>
        <li className='header__item'>
          <a href='#experience' 
            className={`header__link ${nameLink === 'experience' && 'link-active'}`}
            onClick={() => handleLinkActive('experience')}>
            <IoSchoolOutline />
            <span className={`header__link--text ${ open && 'text-open'}`}>
            {lang === 'pt-br' ? pt.experience : en.experience}
            </span>
          </a>
        </li>
        <li className='header__item'>
          <a href='#work' 
            className={`header__link ${nameLink === 'work' && 'link-active'}`}
            onClick={() => handleLinkActive('work')}>
            <FiLayers />
            <span className={`header__link--text ${ open && 'text-open'}`}>
            {lang === 'pt-br' ? pt.work : en.work}
            </span>
          </a>
        </li>
        <li className='header__item'>
          <a href='#posts' 
            className={`header__link ${nameLink === 'post' && 'link-active'}`}
            onClick={() => handleLinkActive('post')}>
            <RiFilePaper2Line />
            <span className={`header__link--text ${ open && 'text-open'}`}>
            {lang === 'pt-br' ? pt.post : en.post}
            </span>
          </a>
        </li>
        <li className='header__item'>
          <a href='#contact' 
            className={`header__link ${nameLink === 'contact' && 'link-active'}`}
            onClick={() => handleLinkActive('contact')}>
            <IoChatbubblesOutline />
            <span className={`header__link--text ${ open && 'text-open'}`}>
            {lang === 'pt-br' ? pt.contact : en.contact}
            </span>
          </a>
        </li>
      </ul>
      <ul className='header__list buttons-mode'>
        <li className='header__item button-color-mode'>
          <button
            className='button-color-mode__side button-color-mode__side--front' 
            style={rotate ? {transform: 'rotateX(-180deg)'} : {color: '#F48B29'}}
            onClick={() => handleTheme('dark')}>
            <BsSun />
            <span className={`header__link--text ${ open && 'text-open'}`}>
            {lang === 'pt-br' ? pt.themeLight : en.themeLight}
            </span>
          </button>
          <button
            className='button-color-mode__side button-color-mode__side--back'
            style={rotate ? {transform: 'rotateX(0)'} : {}}
            onClick={() => handleTheme('light')}>
            <BsMoon />
            <span className={`header__link--text ${ open && 'text-open'}`}>
            {lang === 'pt-br' ? pt.themeDark : en.themeDark}
            </span>
          </button>
        </li>
        <li className='header__item mode-lang'>
          <button 
            className='mode-lang__button' 
            onClick={() => setOpen(true)}
            aria-label="translate"
          >
            <IoLanguageSharp />
          </button>
          { open &&
            <select
              className='mode-lang__select'
              value={lang}
              onChange={(e) => handleLang(e)}>
              <option value="pt-br" className='mode-lang__select--item'>Português</option>
              <option value="en-us" className='mode-lang__select--item'>Inglês</option>
            </select>
          }
        </li>
        <li className='header__item'>
          {!handleHeader &&
            <button
              className='button-header-arrow'
              onClick={handleHeaderOpen}
              style={open ? {transform: 'rotateZ(-180deg)'} : {}}
              aria-label='closed'>
              <ImArrowRight />
            </button>
          }
        </li>
      </ul>
    </div>
  )
}

export default Header