import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.scss';
// import reportWebVitals from './reportWebVitals';

import {disableReactDevTools} from './disableReactDevTools'
import { ThemeProvider } from './hooks/themes/themes'
import { LanguageProvider } from './hooks/language/language'
import { ActivedLinkProvider } from './hooks/fade/fade'
import App from './App';

if (process.env.NODE_ENV === "production") disableReactDevTools();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider>
        <LanguageProvider>
          <ActivedLinkProvider>
            <App />
          </ActivedLinkProvider>
        </LanguageProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals();
